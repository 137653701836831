export const pagesContentIniValues = {
  _id: "",
  aboutUs: {
    heroBanner: "",
  },
  service: {
    heroBanner: "",
  },
  contactUs: {
    heroBanner: "",
  },
  career: {
    heroBanner: "",
  },
};
