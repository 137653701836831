import React, { useEffect } from "react";
import ContentBlock from "../../../components/ContentBlock/ContentBlock";
import { Box, Button, Divider, Grid, TextInput, Title } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { forms } from "../../../forms";
import { showNotification } from "@mantine/notifications";
import { useSettingsQuery } from "../../../hooks/settings/query/useSettings.query";
import { useUpdateSettingsMutation } from "../../../hooks/settings/mutation/useUpdateSettings.mutation";

const SocialLinks = () => {
  const { isLoading, data } = useSettingsQuery();
  const { isPending, mutateAsync } = useUpdateSettingsMutation();
  const { getInputProps, onSubmit, setValues } = useForm({
    initialValues: forms.settings.values,
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: yupResolver(forms.settings.validation),
  });

  useEffect(() => {
    if (!isLoading && data) {
      setValues(data.data);
    } else {
      setValues(forms.settings.values);
    }
  }, [isLoading, data]);

  const handleSubmit = async (values: typeof forms.settings.values) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  };

  return (
    <ContentBlock title="Social Links & Contact" loading={isLoading}>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Box>
          <Title mb={10} order={3}>
            Social Urls
          </Title>
          <Grid gutter={10}>
            <Grid.Col lg={6}>
              <TextInput
                label="Instagram"
                {...getInputProps("socialLinks.instagram")}
                type="url"
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextInput
                label="Facebook"
                {...getInputProps("socialLinks.facebook")}
                type="url"
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextInput
                label="Twitter"
                {...getInputProps("socialLinks.twitter")}
                type="url"
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextInput
                label="Linked In"
                {...getInputProps("socialLinks.linkedIn")}
                type="url"
              />
            </Grid.Col>
          </Grid>
        </Box>
        <Divider my={25} opacity={0.5} />

        <Box>
          <Title mb={10} order={3}>
            Contact Details
          </Title>
          <Grid gutter={10}>
            <Grid.Col lg={6}>
              <TextInput
                label="Telephone"
                {...getInputProps("contact.telephone")}
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextInput label="Mobile" {...getInputProps("contact.mobile")} />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextInput
                label="Email"
                {...getInputProps("contact.email")}
                type="email"
              />
            </Grid.Col>
            <Grid.Col lg={6}>
              <TextInput
                label="Website Url"
                {...getInputProps("contact.websiteUrl")}
                type="url"
              />
            </Grid.Col>
          </Grid>
        </Box>

        <Button loading={isPending} disabled={isPending} type="submit" mt={20}>
          Update Links
        </Button>
      </form>
    </ContentBlock>
  );
};

export default SocialLinks;
