import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Notifications } from "@mantine/notifications";
import { MantineProvider } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./routes/appRoutes";
import { customTheme } from "./assets/theme/globalTheme.theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "react-auth-kit";
import ImagePicker, {
  ImageCropPickerConsumer,
} from "./components/ImageCropPicker/ImagePicker";
import { ModalsProvider } from "@mantine/modals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <AuthProvider
    authType={"cookie"}
    authName={`${process.env.REACT_APP_AUTH_NAME}`}
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === "https:"}
  >
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={customTheme} withGlobalStyles withNormalizeCSS>
        <ModalsProvider>
          <ImagePicker>
            <RouterProvider router={appRouter} />
          </ImagePicker>
        </ModalsProvider>
        <Notifications position="top-right" />
      </MantineProvider>
      <ReactQueryDevtools
        position="bottom"
        buttonPosition="bottom-right"
        initialIsOpen={false}
      />
    </QueryClientProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
