import {
  IconAddressBook,
  IconBrandBlogger,
  IconCategory,
  IconDashboard,
  IconHome,
  IconPaperclip,
  IconPhoto,
  IconServer2,
  IconSettings,
  IconSocial,
  IconUsersGroup,
} from "@tabler/icons-react";
import { MainLinkProps } from "./MainLinks";
import React from "react";

const iconSize = 18;

export const naves: MainLinkProps[] = [
  {
    icon: <IconDashboard size={iconSize} />,
    color: "primary",
    label: "Dashboard",
    to: "/",
  },
  {
    icon: <IconPaperclip size={iconSize} />,
    color: "primary",
    label: "Pages",
    to: "/pages",
  },
  {
    icon: <IconHome size={iconSize} />,
    color: "primary",
    label: "Blogs Page",
    to: "/blogs-page",
    children: [
      {
        icon: <IconPhoto size={iconSize} />,
        color: "primary",
        label: "Blog Banners",
        to: "/blogs-banner",
      },
      {
        icon: <IconBrandBlogger size={iconSize} />,
        color: "primary",
        label: "Blogs",
        to: "/blogs",
      },
    ],
  },
  {
    icon: <IconHome size={iconSize} />,
    color: "primary",
    label: "Home Page",
    to: "/home-page",
    children: [
      {
        icon: <IconPhoto size={iconSize} />,
        color: "primary",
        label: "Home Banners",
        to: "/home-banners",
      },
      {
        icon: <IconServer2 size={iconSize} />,
        color: "primary",
        label: "Services",
        to: "/services",
      },
      {
        icon: <IconCategory size={iconSize} />,
        color: "primary",
        label: "Projects",
        to: "/projects",
      },
      {
        icon: <IconUsersGroup size={iconSize} />,
        color: "primary",
        label: "Partners",
        to: "/partners",
      },
    ],
  },
  {
    icon: <IconSettings size={iconSize} />,
    color: "primary",
    label: "Settings",
    to: "/settings",
    children: [
      {
        icon: <IconSocial size={iconSize} />,
        color: "primary",
        label: "Social Links & Contact",
        to: "/social-urls-and-contact",
      },
      {
        icon: <IconAddressBook size={iconSize} />,
        color: "primary",
        label: "Addresses",
        to: "/addresses",
      },
    ],
  },
];
