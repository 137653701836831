import React, { useEffect, useMemo, useState } from "react";
import { usePagesContentQuery } from "../../hooks/pages-content/query/usePagesContent.query";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import PagesTabs from "./component/PagesTabs";
import { useForm, yupResolver } from "@mantine/form";
import { forms } from "../../forms";
import { Button } from "@mantine/core";
import { useUpdatePagesContentMutation } from "../../hooks/pages-content/mutation/useUpdatePagesContent.mutation";
import { showNotification } from "@mantine/notifications";

const PagesContent = () => {
  const { data, isLoading, refetch } = usePagesContentQuery();

  const form = useForm({
    initialValues: forms.pagesContent.values,
  });

  const { setValues, onSubmit } = form;

  const pagesContent: TPagesContent = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  const { isPending, mutateAsync } = useUpdatePagesContentMutation();

  const handleSubmit = async (values: typeof forms.pagesContent.values) => {
    const formData = new FormData();
    if (typeof values.aboutUs.heroBanner !== "string") {
      formData.append(
        "aboutHeroBanner",
        values.aboutUs.heroBanner,
        "heroBanner.jpg"
      );
    }
    if (typeof values.service.heroBanner !== "string") {
      formData.append(
        "serviceHeroBanner",
        values.service.heroBanner,
        "heroBanner.jpg"
      );
    }
    if (typeof values.career.heroBanner !== "string") {
      formData.append(
        "careerHeroBanner",
        values.career.heroBanner,
        "heroBanner.jpg"
      );
    }
    if (typeof values.contactUs.heroBanner !== "string") {
      formData.append(
        "contactHeroBanner",
        values.contactUs.heroBanner,
        "heroBanner.jpg"
      );
    }

    formData.append("_id", values._id);
    formData.append("pages", JSON.stringify(values));
    const res = await mutateAsync(formData);
    if (res.status === "success") {
      refetch();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data?.message, color: "red" });
    }
  };

  useEffect(() => {
    if (pagesContent) {
      setValues(pagesContent);
    }
  }, [pagesContent, setValues]);

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <ContentBlock
        title="Pages Content"
        loading={isLoading}
        rightComponent={
          <Button
            type="submit"
            size="xs"
            loading={isPending}
            disabled={isPending}
          >
            Save Details
          </Button>
        }
      >
        <PagesTabs pagesContent={pagesContent} handler={form} />
      </ContentBlock>
    </form>
  );
};

export default PagesContent;
