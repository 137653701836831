import * as Yup from "yup";

export const pagesContentValidation = Yup.object().shape({
  aboutUs: Yup.object().shape({
    heroBanner: Yup.string().required("Hero banner is required"),
  }),
  service: Yup.object().shape({
    heroBanner: Yup.string().required("Hero banner is required"),
  }),
  contactUs: Yup.object().shape({
    heroBanner: Yup.string().required("Hero banner is required"),
  }),
  career: Yup.object().shape({
    heroBanner: Yup.string().required("Hero banner is required"),
  }),
});
