import { Tabs } from "@mantine/core";
import React, { FC, memo, useState } from "react";
import AboutUsPage from "./AboutUsPage";
import ServicesPage from "./ServicesPage";
import CareerPage from "./CareerPage";
import ContactUsPage from "./ContactUsPage";
import { UseFormReturnType } from "@mantine/form";

interface IPagesTabs {
  pagesContent: TPagesContent;
  handler: UseFormReturnType<TPagesContent>;
}

const PagesTabs: FC<IPagesTabs> = ({ handler }) => {
  const [currentPage, setCurrentPage] = useState<string | null>("aboutUs");
  return (
    <Tabs value={currentPage} onTabChange={setCurrentPage}>
      <Tabs.List>
        <Tabs.Tab value="aboutUs">About Us</Tabs.Tab>
        <Tabs.Tab value="service">Service</Tabs.Tab>
        <Tabs.Tab value="career">Career</Tabs.Tab>
        <Tabs.Tab value="contactUs">Contact Us</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="aboutUs">
        <AboutUsPage form={handler} />
      </Tabs.Panel>

      <Tabs.Panel value="service">
        <ServicesPage form={handler} />
      </Tabs.Panel>

      <Tabs.Panel value="career">
        <CareerPage form={handler} />
      </Tabs.Panel>
      <Tabs.Panel value="contactUs">
        <ContactUsPage form={handler} />
      </Tabs.Panel>
    </Tabs>
  );
};

export default memo(PagesTabs);
