import ContentBlock from "../../components/ContentBlock/ContentBlock";
import React from "react";

const Dashboard = () => {
  return (
    <ContentBlock loading={false} title="Dashboard">
      <div>called</div>
    </ContentBlock>
  );
};

export default Dashboard;
