import { memo, useEffect } from "react";
import React from "react";

import TheLayout from "../container/TheLayout";
import { useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login", { replace: true });
    }
  }, [navigate, isAuthenticated]);

  return <TheLayout />;
};

export default memo(ProtectedRoute);
