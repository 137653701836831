import React, { useMemo, useState } from "react";
import ContentBlock from "../../../components/ContentBlock/ContentBlock";
import { Button } from "@mantine/core";
import DefaultTable from "../../../components/table/DefaultTable";
import { CONSTANTS } from "../../../constants";
import { COLUMNS } from "../../../columns";
import ActionButton from "./components/ActionButton";
import { useProjectsQuery } from "../../../hooks/projects/query/useProjects.query";
import ProjectModal from "./components/ProjectModal";

const HomeProjects = () => {
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState({ total: 0 });
  const { data, isLoading, refetch } = useProjectsQuery({
    itemPerPage: CONSTANTS.PAGE_LIMIT,
    page: activePage,
  });

  const [projectModal, setProjectModal] = useState<{
    visible: boolean;
    data: TProjects | undefined;
  }>({
    visible: false,
    data: undefined,
  });

  const columns = [...COLUMNS.PROJECTS_DESCRIPTION];

  const projects: TProjects[] = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data?.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  columns.push({
    label: "Actions",
    key: "actions",
    renderCell: (row) => (
      <ActionButton
        data={row}
        refetch={refetch}
        onEditClick={() =>
          setProjectModal({
            data: row,
            visible: true,
          })
        }
      />
    ),
  });

  return (
    <ContentBlock
      title="Home Projects"
      loading={false}
      rightComponent={
        <Button
          onClick={() =>
            setProjectModal({
              data: undefined,
              visible: true,
            })
          }
          variant="filled"
          color="blue"
        >
          Add Project
        </Button>
      }
    >
      <DefaultTable
        columns={columns as TTableColumns<unknown>[]}
        data={projects}
        isLoading={isLoading}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
      {projectModal.visible && (
        <ProjectModal
          onClose={() => setProjectModal({ visible: false, data: undefined })}
          show={projectModal.visible}
          refetch={refetch}
          data={projectModal.data}
        />
      )}
    </ContentBlock>
  );
};

export default HomeProjects;
