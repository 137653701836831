import { authApis } from "./auth.api";
import { blogBannerApi } from "./blogBanner.api";
import { blogsApi } from "./blogs.api";
import { homeBannerApis } from "./homeBanner.api";
import { pagesContentApi } from "./pagesContent.api";
import { partnersApis } from "./partners.api";
import { projectsApis } from "./projects.api";
import { homeServicesApis } from "./services.api";
import { settingsApi } from "./settings.api";

export const apiUrls = {
  ...authApis,
  homeBanner: homeBannerApis,
  settings: settingsApi,
  homeServices: homeServicesApis,
  projects: projectsApis,
  partners: partnersApis,
  pagesContent: pagesContentApi,
  blogBanner: blogBannerApi,
  blogs: blogsApi,
};
