import { Box, Grid, Image } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import React, { FC, memo } from "react";
import CropPickerInput from "../../../components/ImageCropPicker/CropPickerInput";

interface IAboutUsPage {
  form: UseFormReturnType<TPagesContent>;
}

const AboutUsPage: FC<IAboutUsPage> = ({ form }) => {
  return (
    <Box my={20}>
      <Grid>
        <Grid.Col lg={12}>
          {form.values?.aboutUs?.heroBanner && (
            <Image
              src={
                typeof form.values?.aboutUs?.heroBanner === "string"
                  ? form.values?.aboutUs?.heroBanner
                  : URL.createObjectURL(form.values?.aboutUs?.heroBanner)
              }
              my={10}
            />
          )}
          <CropPickerInput
            name={"aboutUs.heroBanner"}
            label="Hero Banner (ratio: 113 × 40)"
            formHandler={form}
            pickerRatio={113 / 40}
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default memo(AboutUsPage);
