import React, { memo, useEffect } from "react";
import { useForm, yupResolver } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import {
  TextInput,
  Button,
  Grid,
  Box,
  Image,
  Text,
  Textarea,
  Radio,
  Group,
} from "@mantine/core";
import CropPickerInput from "../../../../components/ImageCropPicker/CropPickerInput";
import FRichTextEditor from "../../../../components/text-editor/FRichTextEditor";
import { useAddBlogsMutation } from "../../../../hooks/blogs/mutation/useAddBlogs.mutation";
import { useUpdateBlogsMutation } from "../../../../hooks/blogs/mutation/useUpdateBlogs.mutation";
import { useGetSingleBlogsQuery } from "../../../../hooks/blogs/query/useGetSingleBlogs.query";
import { showNotification } from "@mantine/notifications";
import { useNavigate, useParams } from "react-router-dom";
import { forms } from "../../../../forms";
import ContentBlock from "../../../../components/ContentBlock/ContentBlock";

const AddBlogs = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading, refetch } = useGetSingleBlogsQuery(id ?? "");

  const form = useForm<TBlogs>({
    initialValues: {
      ...forms.blogs.values,
      active: true,
      date: new Date(), // Ensure initial date is a Date object
    },
    validate: yupResolver(forms.blogs.validation),
  });

  const { mutateAsync: createBlogs, isPending: createLoading } =
    useAddBlogsMutation();
  const { mutateAsync: updateBlogs, isPending: updateLoading } =
    useUpdateBlogsMutation();

  useEffect(() => {
    if (id && data?.data) {
      const blogToEdit = data.data;

      if (blogToEdit._id === id) {
        // Map sections to ensure proper handling of pageImage
        const sections = blogToEdit.sections.map(
          (section: { pageImage: any }) => ({
            ...section,
            pageImage: section.pageImage || null, // Ensure pageImage is either a Blob, string, or null
          })
        );

        form.setValues({
          ...blogToEdit,
          date: new Date(blogToEdit.date),
          sections: sections,
        });
      } else {
        console.error("Blog not found with id:", id);
      }
    } else {
      console.error("data error:", id);
    }
  }, [id, data]);

  const handleSubmit = async (values: TBlogs) => {
    const formData = new FormData();

    const isBlob = (obj: any): obj is Blob => obj instanceof Blob;

    if (values.image && isBlob(values.image)) {
      formData.append("image", values.image, "image.webp");
    } else if (typeof values.image === "string") {
      formData.append("image", values.image); // Preserve the existing image URL or path
    }

    if (values.bloggerImage && isBlob(values.bloggerImage)) {
      formData.append("bloggerImage", values.bloggerImage, "bloggerImage.webp");
    } else if (typeof values.bloggerImage === "string") {
      formData.append("bloggerImage", values.bloggerImage); // Preserve the existing image URL or path
    }

    formData.append("title", values.title);
    formData.append("description", values.description);

    let dateString =
      values.date instanceof Date
        ? values.date.toISOString()
        : new Date(values.date).toISOString();
    formData.append("date", dateString);

    formData.append("bloggerName", values.bloggerName);
    formData.append("active", values.active.toString());

    values.sections.forEach((item, index) => {
      formData.append(
        `sections[${index}][pageDescription]`,
        item.pageDescription
      );

      if (item.pageImage && isBlob(item.pageImage)) {
        formData.append(
          `sections[${index}][pageImage]`,
          item.pageImage as Blob,
          "pageImage.webp"
        );
      } else if (typeof item.pageImage === "string") {
        formData.append(`sections[${index}][pageImage]`, item.pageImage); // Preserve the existing image URL or path
      }

      formData.append(
        `sections[${index}][pageImagePosition]`,
        item.pageImagePosition
      );
    });

    formData.append("_id", values._id);

    try {
      let res;
      if (id) {
        res = await updateBlogs(formData);
      } else {
        res = await createBlogs(formData);
      }

      if (res.status === "success") {
        refetch();
        navigate("/blogs-page/blogs");
        showNotification({ message: res.message, color: "green" });
      } else {
        showNotification({
          message: res.message || "An error occurred",
          color: "red",
        });
      }
    } catch (error) {
      console.error("Submission error:", error);
      showNotification({ message: "An error occurred", color: "red" });
    }
  };

  const addSection = () => {
    form.insertListItem("sections", {
      pageDescription: "",
      pageImage: null,
      pageImagePosition: "",
    });
  };

  const removeSection = (index: number) => {
    form.removeListItem("sections", index);
  };

  return (
    <ContentBlock
      title={id ? "Edit Blog Detail" : "Add Blog Detail"}
      loading={isLoading}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div>
          {form.values?.image && (
            <Image
              src={
                typeof form.values?.image === "string"
                  ? form.values?.image
                  : URL.createObjectURL(form.values?.image)
              }
              my={10}
              alt="Image"
              width={300}
            />
          )}

          <CropPickerInput
            name={"image"}
            label="Image (ratio: 471 × 401)"
            formHandler={form}
            pickerRatio={471 / 401}
          />
          <DateInput
            label="Select a date"
            placeholder="Pick a date"
            {...form.getInputProps("date")}
            mt={20}
            valueFormat="DD MMM YYYY"
          />
          <Grid mt={16}>
            <Grid.Col span={6}>
              <Textarea
                label="Title"
                placeholder="Title"
                {...form.getInputProps("title")}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Textarea
                label="Description"
                placeholder="description"
                {...form.getInputProps("description")}
              />
            </Grid.Col>
          </Grid>

          <Text fz="xl" mt={20}>
            Page Details
          </Text>
          {form.values?.bloggerImage && (
            <Image
              src={
                typeof form.values?.bloggerImage === "string"
                  ? form.values?.bloggerImage
                  : URL.createObjectURL(form.values?.bloggerImage)
              }
              my={10}
              alt="Image"
              width={100}
            />
          )}
          <Grid mt={16} mb={20}>
            <Grid.Col span={6}>
              <CropPickerInput
                name={"bloggerImage"}
                label="Author Image (ratio: 92 x 92)"
                formHandler={form}
                pickerRatio={92 / 92}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                placeholder="Your name"
                label="Author Name"
                {...form.getInputProps("bloggerName")}
              />
            </Grid.Col>
          </Grid>

          {form.values.sections.map((_, index) => (
            <Box
              key={index + 1}
              mt={20}
              style={{ paddingBottom: 20, borderBottom: "1px solid #b7acac" }}
            >
              <FRichTextEditor
                content={form.values.sections[index].pageDescription}
                label="Section Description"
                onChange={(value) =>
                  form.setFieldValue(`sections.${index}.pageDescription`, value)
                }
              />
              {form.errors[`sections.${index}.pageDescription`] && (
                <Text color="red">
                  {form.errors[`sections.${index}.pageDescription`]}
                </Text>
              )}
              {form.values?.sections[index]?.pageImage && (
                <Image
                  src={
                    typeof form.values?.sections[index]?.pageImage === "string"
                      ? form.values?.sections[index]?.pageImage
                      : URL.createObjectURL(
                          form.values.sections[index].pageImage as any
                        )
                  }
                  my={10}
                  alt="Image"
                  width={100}
                />
              )}
              <Grid mt={20}>
                <Grid.Col span={4}>
                  <CropPickerInput
                    name={`sections.${index}.pageImage`}
                    label="Page Image (ratio: 700 x 675)"
                    formHandler={form}
                    pickerRatio={700 / 675}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Radio.Group
                    name={`sections.${index}.pageImagePosition`}
                    label="Image Position"
                    withAsterisk
                    {...form.getInputProps(
                      `sections.${index}.pageImagePosition`
                    )}
                  >
                    <Group mt="xs">
                      <Radio value="left" label="Left" />
                      <Radio value="right" label="Right" />
                    </Group>
                  </Radio.Group>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Button
                    mt={30}
                    variant="outline"
                    color="red"
                    onClick={() => removeSection(index)}
                  >
                    Remove Section
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          ))}

          <Button
            variant="light"
            color="blue"
            mt={20}
            onClick={addSection}
            mr={10}
          >
            Add Section
          </Button>

          <Button
            type="submit"
            variant="filled"
            color="blue"
            mt={20}
            loading={createLoading || updateLoading}
            disabled={createLoading || updateLoading}
          >
            {id ? "Update Detail" : "Save Detail"}
          </Button>
        </div>
      </form>
    </ContentBlock>
  );
};

export default memo(AddBlogs);
