export const TSection = [
  {
    pageDescription: "",
    pageImage: "",
    pageImagePosition: "",
  },
];

export const blogsIniValues = {
  image: "",
  date: new Date(),
  title: "",
  description: "",
  _id: "",
  bloggerImage: "",
  bloggerName: "",
  sections: TSection,
};
