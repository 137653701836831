import { createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import ErrorPage from "../pages/error/ErrorPage";
import Login from "../pages/login/Login";
import ProtectedRoute from "../routers/Protected.route";
import React from "react";
import HomeBanners from "../pages/home-page/home-banners/HomeBanners";
import SocialLinks from "../pages/settings/social-links/SocialLinks";
import Addresses from "../pages/settings/addresses/Addresses";
import HomeServices from "../pages/home-page/home-services/HomeServices";
import HomeProjects from "../pages/home-page/home-projects/HomeProjects";
import HomePartners from "../pages/home-page/home-partners/HomePartners";
import PagesContent from "../pages/pages-content/PagesContent";
import BlogsBanner from "../pages/blogs-page/BlogsBanner";
import Blogs from "../pages/blogs-page/blogs/Blogs";
import AddBlogs from "../pages/blogs-page/blogs/components/AddBlogs";

export const appRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: "/",
          element: <Dashboard />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/home-page/home-banners",
          element: <HomeBanners />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/home-page/services",
          element: <HomeServices />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/home-page/projects",
          element: <HomeProjects />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/home-page/partners",
          element: <HomePartners />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/settings/social-urls-and-contact",
          element: <SocialLinks />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/settings/addresses",
          element: <Addresses />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/pages",
          element: <PagesContent />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/blogs-page/blogs-banner",
          element: <BlogsBanner />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/blogs-page/blogs",
          element: <Blogs />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/blogs-page/blogs/add-blogs",
          element: <AddBlogs />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/blogs-page/blogs/edit/:id",
          element: <AddBlogs />,
          errorElement: <ErrorPage />,
        },
      ],
      errorElement: <ErrorPage />,
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
  ],
  {
    basename: process.env.REACT_APP_INSTALLATION_PATH,
  }
);
