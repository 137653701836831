import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async (paging?: TPaging) => {
  const response: TServerResponse = await request({
    url: apiUrls.projects.FETCH_PROJECTS,
    method: "GET",
    params: { ...paging },
  });
  return response;
};

export const useProjectsQuery = (paging: TPaging) => {
  return useQuery({
    queryKey: [apiUrls.projects.FETCH_PROJECTS, paging],
    queryFn: () => get(paging),
  });
};
