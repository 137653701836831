import React, { useMemo, useState } from "react";
import ContentBlock from "../../../components/ContentBlock/ContentBlock";
import { Button } from "@mantine/core";
import DefaultTable from "../../../components/table/DefaultTable";

import { CONSTANTS } from "../../../constants";
import { COLUMNS } from "../../../columns";

import ActionButton from "./components/ActionButton";
import { usePartnersQuery } from "../../../hooks/partners/query/usePartners.query";
import PartnerModal from "./components/PartnerModal";

const HomePartners = () => {
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState({ total: 0 });
  const { data, isLoading, refetch } = usePartnersQuery({
    itemPerPage: CONSTANTS.PAGE_LIMIT,
    page: activePage,
  });

  const [partnerModal, setPartnerModal] = useState<{
    visible: boolean;
    data: TPartners | undefined;
  }>({
    visible: false,
    data: undefined,
  });

  const columns = [...COLUMNS.PARTNERS];

  const partners = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data?.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  columns.push({
    label: "Actions",
    key: "actions",
    renderCell: (row) => (
      <ActionButton
        data={row}
        refetch={refetch}
        onEditClick={() =>
          setPartnerModal({
            data: row,
            visible: true,
          })
        }
      />
    ),
  });

  return (
    <ContentBlock
      title="Home Partners"
      loading={false}
      rightComponent={
        <Button
          onClick={() =>
            setPartnerModal({
              data: undefined,
              visible: true,
            })
          }
          variant="filled"
          color="blue"
        >
          Add Partner
        </Button>
      }
    >
      <DefaultTable
        columns={columns as TTableColumns<unknown>[]}
        data={partners}
        isLoading={isLoading}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
      {partnerModal.visible && (
        <PartnerModal
          onClose={() => setPartnerModal({ visible: false, data: undefined })}
          show={partnerModal.visible}
          refetch={refetch}
          data={partnerModal.data}
        />
      )}
    </ContentBlock>
  );
};

export default HomePartners;
