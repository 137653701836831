import { ActionIcon, Flex, Text } from "@mantine/core";
import React, { memo, useCallback } from "react";
import { showNotification } from "@mantine/notifications";
import { IconEdit, IconEye, IconEyeOff, IconTrash } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { useUpdateBlogsStatusMutation } from "../../../../hooks/blogs/mutation/useUpdateBlogsStatus.mutation";
import { useRemoveBlogsMutation } from "../../../../hooks/blogs/mutation/useRemoveBlogs.mutation";

interface IActionButton {
  data: TBlogs;
  onEditClick: (id: string) => void;
  refetch: () => void;
}

const ActionButton: React.FC<IActionButton> = ({
  onEditClick,
  data: { _id, active },
  refetch,
}) => {
  const { mutateAsync: updateStatusMutate, isPending } =
    useUpdateBlogsStatusMutation();

  const { mutateAsync: removeLink, isPending: removeLoading } =
    useRemoveBlogsMutation();

  const updateStatus = useCallback(async () => {
    if (isPending) {
      return null;
    }
    const res = await updateStatusMutate({ _id: _id, status: active });
    if (res.status === "success") {
      refetch();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  }, [_id, isPending, updateStatusMutate, active]);

  const removeVideo = useCallback(async () => {
    if (removeLoading) {
      return null;
    }
    const res = await removeLink({ _id: _id });
    if (res.status === "success") {
      modals.closeAll();
      refetch();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  }, [_id, removeLoading, removeLink]);

  return (
    <Flex sx={{ alignSelf: "center" }}>
      <ActionIcon color="blue" variant="outline" onClick={updateStatus}>
        {active ? <IconEye /> : <IconEyeOff />}
      </ActionIcon>
      <ActionIcon
        color="blue"
        mx={10}
        variant="outline"
        onClick={() => onEditClick(_id)}
      >
        <IconEdit />
      </ActionIcon>
      <ActionIcon
        onClick={() =>
          modals.openConfirmModal({
            title: "Please confirm your action",
            children: (
              <Text size="sm">Are you sure you want to delete this Blog</Text>
            ),
            labels: { confirm: "Confirm", cancel: "Cancel" },

            onConfirm: removeVideo,
            centered: true,
          })
        }
        disabled={removeLoading}
        color="red"
        variant="outline"
      >
        <IconTrash />
      </ActionIcon>
    </Flex>
  );
};

export default memo(ActionButton);
