import { useForm, yupResolver } from "@mantine/form";
import React, { FC, memo } from "react";
import { forms } from "../../../../forms";
import {
  Button,
  FileInput,
  Grid,
  Image,
  Modal,
  TextInput,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import CropPickerInput from "../../../../components/ImageCropPicker/CropPickerInput";
import { useAddServiceMutation } from "../../../../hooks/home-services/mutation/useAddService.mutation";
import { useUpdateServiceMutation } from "../../../../hooks/home-services/mutation/useUpdateService.mutation";

interface IServiceModal {
  show: boolean;
  onClose: () => void;
  refetch: () => void;
  data?: TServices;
}

const ServiceModal: FC<IServiceModal> = ({ data, onClose, show, refetch }) => {
  const formHandler = useForm({
    initialValues: {
      ...forms.homeService.values,
      ...data,
    },
    validate: yupResolver(forms.homeService.validation),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const { onSubmit, getInputProps } = formHandler;

  const { mutateAsync: createService, isPending: createLoading } =
    useAddServiceMutation();
  const { mutateAsync: updateService, isPending: updateLoading } =
    useUpdateServiceMutation();

  const handleSubmit = async (values: typeof forms.homeService.values) => {
    const formData = new FormData();
    if (typeof values.icon !== "string") {
      formData.append("icon", values.icon, "icon.png");
    }
    formData.append("title", values.title);
    formData.append("_id", values._id);
    const res = data
      ? await updateService(formData)
      : await createService(formData);
    if (res.status === "success") {
      onClose();
      refetch();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  };

  return (
    <Modal
      opened={show}
      onClose={onClose}
      title={data ? "Edit Service" : "Add Service"}
      size={"md"}
    >
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid gutter={10}>
          <Grid.Col lg={12}>
            <TextInput {...getInputProps("title")} label="Title" />
          </Grid.Col>

          <Grid.Col lg={12}>
            <CropPickerInput
              name="icon"
              label="Icon (ratio: 114 × 114)"
              formHandler={formHandler}
              pickerRatio={114 / 114}
            />
            {formHandler.values?.icon && (
              <Image
                src={
                  typeof formHandler.values?.icon === "string"
                    ? formHandler.values?.icon
                    : URL.createObjectURL(formHandler.values?.icon)
                }
                my={10}
              />
            )}
          </Grid.Col>
          <Grid.Col lg={12}>
            <Button
              loading={createLoading || updateLoading}
              disabled={createLoading || updateLoading}
              type="submit"
              fullWidth
              variant="filled"
              color="cyan"
            >
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default memo(ServiceModal);
