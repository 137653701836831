import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async (paging?: TPaging) => {
  const response: TServerResponse = await request({
    url: apiUrls.homeServices.FETCH_SERVICES,
    method: "GET",
    params: { ...paging },
  });
  return response;
};

export const useHomeServicesQuery = (paging: TPaging) => {
  return useQuery({
    queryKey: [apiUrls.homeServices.FETCH_SERVICES, paging],
    queryFn: () => get(paging),
  });
};
