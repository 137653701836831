import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.blogBanner.FETCH_BLOG_BANNER,
    method: "GET",
  });
  return response;
};

export const useBlogBannerQuery = () => {
  return useQuery({
    queryKey: [apiUrls.blogBanner.FETCH_BLOG_BANNER],
    queryFn: get,
  });
};
