import * as Yup from "yup";

export const blogsValidation = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("description is required"),
  image: Yup.string().required("image is required"),
  date: Yup.date().required("Date is required"),

  bloggerName: Yup.string().required("blog Name is required"),
  sections: Yup.array().of(
    Yup.object().shape({
      pageDescription: Yup.string().required("page description is required"),
    })
  ),
});
