import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async (_id: string) => {
  const response: TServerResponse = await request({
    url: apiUrls.blogs.GET_SINGLE_BLOGS,
    method: "GET",
    params: { _id },
  });
  return response;
};

export const useGetSingleBlogsQuery = (id: string) => {
  return useQuery({
    queryKey: [apiUrls.blogs.GET_SINGLE_BLOGS, id],
    queryFn: () => get(id),
  });
};
