import * as Yup from "yup";

export const settingsValidation = Yup.object().shape({
  contact: Yup.object().shape({
    mobile: Yup.string()
      .required("Mobile field is required")
      .matches(/^(\+91[\s]?)?[0]?(91)?\d{10}$/, "Enter a valid phone number"),
    telephone: Yup.string().required("Mobile field is required"),

    email: Yup.string().email().required("Email is required"),
    websiteUrl: Yup.string().url().required("Website url is required"),
  }),
  socialLinks: Yup.object().shape({
    facebook: Yup.string().url().required("Facebook url is required"),
    instagram: Yup.string().url().required("Instagram url is required"),
    twitter: Yup.string().url().required("Twitter url is required"),
    linkedIn: Yup.string().url().required("LinkedIn url is required"),
  }),
  addresses: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Title is required"),
        address: Yup.string().required("Address is required"),
      })
    )
    .min(1, "At least one address is required")
    .max(5, "You can add up to 5 addresses"),
});
