import { Image } from "@mantine/core";
import React from "react";

export const homeServicesColumns: TTableColumns<TServices>[] = [
  {
    label: "Icon",
    key: "icon",
    renderCell: (value) => <Image width={75} src={value.icon} />,
    minWidth: 200,
  },
  {
    label: "Title",
    key: "title",
  },
];
