import { Image } from "@mantine/core";
import React from "react";

export const blogsColumns: TTableColumns<TBlogs>[] = [
  {
    label: "Banner",
    key: "banner",
    renderCell: (value) => <Image width={60} src={value.image} />,
    minWidth: 100,
  },
  {
    label: "Date",
    key: "date",
    renderCell: (value) => new Date(value.date).toLocaleDateString(),
    minWidth: 60,
  },
  {
    label: "Title",
    key: "title",
    renderCell: (value) => (
      <div dangerouslySetInnerHTML={{ __html: value.title }} />
    ),
    minWidth: 200,
  },
  {
    label: "Description",
    key: "description",
    renderCell: (value) => (
      <div dangerouslySetInnerHTML={{ __html: value.description }} />
    ),
    minWidth: 230,
  },
];
