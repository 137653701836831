export const settingsIniValues = {
  _id: "",
  socialLinks: {
    facebook: "",
    instagram: "",
    twitter: "",
    linkedIn: "",
  },
  contact: {
    mobile: "",
    email: "",
    websiteUrl: "",
    telephone: "",
  },
  addresses: [
    {
      title: "",
      address: "",
    },
  ],
};
