import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { forms } from "../../../forms";

const update = async (data: typeof forms.settings.values) => {
  const response: TServerResponse = await request({
    url: apiUrls.settings.UPDATE_SETTING,
    method: "POST",
    data,
  });
  return response;
};

export const useUpdateSettingsMutation = () => {
  return useMutation({ mutationFn: update });
};
