import { blogBannerValidation } from "./blog-banner/validation";
import { blogBannerIniValues } from "./blog-banner/values";
import { blogsValidation } from "./blogs/validation";
import { blogsIniValues } from "./blogs/values";
import { homeBannerValidation } from "./home-banners/validation";
import { homeBannerIniValues } from "./home-banners/values";
import { partnersValidation } from "./home-partners/validation";
import { partnersIniValues } from "./home-partners/values";
import { projectsValidation } from "./home-projects/validation";
import { projectsIniValues } from "./home-projects/values";
import { homeServicesValidation } from "./home-services/validation";
import { homeServiceIniValues } from "./home-services/values";
import { pagesContentValidation } from "./pages-content/validation";
import { pagesContentIniValues } from "./pages-content/values";
import { settingsValidation } from "./settings/validation";
import { settingsIniValues } from "./settings/values";

export const forms = {
  _id: "",
  homeBanner: {
    values: homeBannerIniValues,
    validation: homeBannerValidation,
  },
  settings: {
    values: settingsIniValues,
    validation: settingsValidation,
  },
  homeService: {
    validation: homeServicesValidation,
    values: homeServiceIniValues,
  },
  projects: {
    values: projectsIniValues,
    validation: projectsValidation,
  },
  partners: {
    values: partnersIniValues,
    validation: partnersValidation,
  },
  pagesContent: {
    values: pagesContentIniValues,
    validation: pagesContentValidation,
  },
  blogBanner: {
    values: blogBannerIniValues,
    validation: blogBannerValidation,
  },
  blogs: {
    values: blogsIniValues,
    validation: blogsValidation,
  },
};
