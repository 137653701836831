import { useForm, yupResolver } from "@mantine/form";
import React, { FC, memo } from "react";
import { forms } from "../../../../forms";
import { Button, FileInput, Grid, Image, Modal } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useAddPartnerMutation } from "../../../../hooks/partners/mutation/useAddPartner.mutation";
import { useUpdatePartnerMutation } from "../../../../hooks/partners/mutation/useUpdatePartner.mutation";

interface IPartnerModal {
  show: boolean;
  onClose: () => void;
  refetch: () => void;
  data?: TPartners;
}

const PartnerModal: FC<IPartnerModal> = ({ data, onClose, show, refetch }) => {
  const formHandler = useForm({
    initialValues: {
      ...forms.partners.values,
      ...data,
    },
    validate: yupResolver(forms.partners.validation),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const { onSubmit, getInputProps } = formHandler;

  const { mutateAsync: createPartner, isPending: createLoading } =
    useAddPartnerMutation();
  const { mutateAsync: updatePartner, isPending: updateLoading } =
    useUpdatePartnerMutation();

  const handleSubmit = async (values: typeof forms.partners.values) => {
    const formData = new FormData();
    if (typeof values.clientLogo !== "string") {
      formData.append("clientLogo", values.clientLogo);
    }
    formData.append("_id", values._id);
    const res = data
      ? await updatePartner(formData)
      : await createPartner(formData);
    if (res.status === "success") {
      onClose();
      refetch();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  };

  return (
    <Modal
      opened={show}
      onClose={onClose}
      title={data ? "Edit Partner" : "Add Partner"}
      size={"md"}
    >
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid gutter={10}>
          <Grid.Col lg={12}>
            {formHandler.values?.clientLogo && (
              <Image
                src={
                  typeof formHandler.values?.clientLogo === "string"
                    ? formHandler.values?.clientLogo
                    : URL.createObjectURL(formHandler.values?.clientLogo)
                }
                my={10}
              />
            )}
            <FileInput {...getInputProps("clientLogo")} label="Partner Logo" />
          </Grid.Col>
          <Grid.Col lg={12}>
            <Button
              loading={createLoading || updateLoading}
              disabled={createLoading || updateLoading}
              type="submit"
              fullWidth
              variant="filled"
              color="cyan"
            >
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default memo(PartnerModal);
