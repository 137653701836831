import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const create = async (data: FormData) => {
  const response: TServerResponse = await request({
    url: apiUrls.homeBanner.UPDATE_BANNER,
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const useUpdateBannerMutation = () => {
  return useMutation({ mutationFn: create });
};
