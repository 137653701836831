import { adminPrefix } from "../../constants";

export const blogsApi = {
  FETCH_BLOGS: adminPrefix + "blogs",
  CREATE_BLOGS: adminPrefix + "/blogs/create",
  UPDATE_BLOGS: adminPrefix + "/blogs/update",
  UPDATE_BLOGS_STATUS: adminPrefix + "/blogs/update-status",
  DELETE_BLOGS: adminPrefix + "/blogs/delete",
  GET_SINGLE_BLOGS: adminPrefix + "/blogs/get-single-blogs",
};
