import { Image } from "@mantine/core";
import React from "react";

export const partnersColumns: TTableColumns<TPartners>[] = [
  {
    label: "Partner Logo",
    key: "clientLogo",
    renderCell: (value) => <Image width={75} src={value.clientLogo} />,
    minWidth: 200,
  },
];
