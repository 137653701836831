import { ActionIcon, Flex, Text } from "@mantine/core";
import React, { memo, useCallback } from "react";
import { showNotification } from "@mantine/notifications";
import { IconEdit, IconEye, IconEyeOff, IconTrash } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { useUpdatePartnerStatusMutation } from "../../../../hooks/partners/mutation/useUpdatePartnerStatus.mutation";
import { useRemovePartnerMutation } from "../../../../hooks/partners/mutation/useRemovePartner.mutation";

interface IActionButton {
  data: TPartners;
  onEditClick: () => void;
  refetch: () => void;
}

const ActionButton: React.FC<IActionButton> = ({
  onEditClick,
  data: { _id, active },
  refetch,
}) => {
  const { mutateAsync: updateStatusMutate, isPending } =
    useUpdatePartnerStatusMutation();

  const { mutateAsync: removeLink, isPending: removeLoading } =
    useRemovePartnerMutation();

  const updateStatus = useCallback(async () => {
    if (isPending) {
      return null;
    }
    const res = await updateStatusMutate({ _id: _id, status: active });
    if (res.status === "success") {
      refetch();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  }, [_id, isPending, updateStatusMutate, active]);

  const remove = useCallback(async () => {
    if (removeLoading) {
      return null;
    }
    const res = await removeLink({ _id: _id });
    if (res.status === "success") {
      modals.closeAll();
      refetch();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data.message, color: "red" });
    }
  }, [_id, removeLoading, removeLink]);

  return (
    <Flex sx={{ alignSelf: "center" }}>
      <ActionIcon color="blue" variant="outline" onClick={updateStatus}>
        {active ? <IconEye /> : <IconEyeOff />}
      </ActionIcon>
      <ActionIcon color="blue" mx={10} variant="outline" onClick={onEditClick}>
        <IconEdit />
      </ActionIcon>
      <ActionIcon
        onClick={() =>
          modals.openConfirmModal({
            title: "Please confirm your action",
            children: (
              <Text size="sm">
                Are you sure you want to delete this partner
              </Text>
            ),
            labels: { confirm: "Confirm", cancel: "Cancel" },

            onConfirm: remove,
            centered: true,
          })
        }
        disabled={removeLoading}
        color="red"
        variant="outline"
      >
        <IconTrash />
      </ActionIcon>
    </Flex>
  );
};

export default memo(ActionButton);
