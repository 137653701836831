import { blogsColumns } from "./blogs.columns";
import { homeBannersColumns } from "./homeBanners.columns";
import { homeServicesColumns } from "./homeServices.columns";
import { partnersColumns } from "./partners.columns";
import { projectsColumns } from "./projects.columns";

export const COLUMNS = {
  HOME_BANNERS: homeBannersColumns,
  HOME_SERVICES: homeServicesColumns,
  PROJECTS_DESCRIPTION: projectsColumns,
  PARTNERS: partnersColumns,
  BLOGS: blogsColumns,
};
