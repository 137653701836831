import React, { useMemo, useState } from "react";
import ContentBlock from "../../../components/ContentBlock/ContentBlock";
import { Button } from "@mantine/core";
import DefaultTable from "../../../components/table/DefaultTable";
import { CONSTANTS } from "../../../constants";
import { COLUMNS } from "../../../columns";

import ActionButton from "./components/ActionButton";
import { useHomeServicesQuery } from "../../../hooks/home-services/query/useHomeServices.query";
import ServiceModal from "./components/ServiceModal";
import { showNotification } from "@mantine/notifications";

const HomeServices = () => {
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState({ total: 0 });
  const { data, isLoading, refetch } = useHomeServicesQuery({
    itemPerPage: CONSTANTS.PAGE_LIMIT,
    page: activePage,
  });

  const [serviceModal, setServiceModal] = useState<{
    visible: boolean;
    data: TServices | undefined;
  }>({
    visible: false,
    data: undefined,
  });

  const columns = [...COLUMNS.HOME_SERVICES];

  const services: TServices[] = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data?.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  columns.push({
    label: "Actions",
    key: "actions",
    renderCell: (row) => (
      <ActionButton
        data={row}
        refetch={refetch}
        onEditClick={() =>
          setServiceModal({
            data: row,
            visible: true,
          })
        }
      />
    ),
  });

  return (
    <ContentBlock
      title="Home Services"
      loading={false}
      rightComponent={
        <Button
          onClick={() => {
            if (services.length >= 10) {
              showNotification({
                color: "red",
                message: "You can add up to 10 services",
              });
            } else {
              setServiceModal({
                data: undefined,
                visible: true,
              });
            }
          }}
          variant="filled"
          color="blue"
        >
          Add Service
        </Button>
      }
    >
      <DefaultTable
        columns={columns as TTableColumns<unknown>[]}
        data={services}
        isLoading={isLoading}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
      {serviceModal.visible && (
        <ServiceModal
          onClose={() => setServiceModal({ visible: false, data: undefined })}
          show={serviceModal.visible}
          refetch={refetch}
          data={serviceModal.data}
        />
      )}
    </ContentBlock>
  );
};

export default HomeServices;
