import { Box, Grid, Image } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import React, { FC, memo } from "react";
import CropPickerInput from "../../../components/ImageCropPicker/CropPickerInput";

interface ICareerPage {
  form: UseFormReturnType<TPagesContent>;
}

const CareerPage: FC<ICareerPage> = ({ form }) => {
  return (
    <Box my={20}>
      <Grid>
        <Grid.Col lg={12}>
          {form.values?.career?.heroBanner && (
            <Image
              src={
                typeof form.values?.career?.heroBanner === "string"
                  ? form.values?.career?.heroBanner
                  : URL.createObjectURL(form.values?.career?.heroBanner)
              }
              my={10}
            />
          )}
          <CropPickerInput
            name={"career.heroBanner"}
            label="Hero Banner (ratio: 113 × 40)"
            formHandler={form}
            pickerRatio={113 / 40}
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default memo(CareerPage);
