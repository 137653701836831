import React, { useMemo, useState } from "react";
import ContentBlock from "../../../components/ContentBlock/ContentBlock";
import { Button } from "@mantine/core";
import DefaultTable from "../../../components/table/DefaultTable";
import { useHomeBannersQuery } from "../../../hooks/home-banners/query/useHomeBanners.query";
import { CONSTANTS } from "../../../constants";
import { COLUMNS } from "../../../columns";
import BannerModal from "./components/BannerModal";
import ActionButton from "./components/ActionButton";

const HomeBanners = () => {
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState({ total: 0 });
  const { data, isLoading, refetch } = useHomeBannersQuery({
    itemPerPage: CONSTANTS.PAGE_LIMIT,
    page: activePage,
  });
  console.log("data Home Banner", data?.data);

  const [bannerModal, setBannerModal] = useState<{
    visible: boolean;
    data: THomePageBanners | undefined;
  }>({
    visible: false,
    data: undefined,
  });

  const columns = [...COLUMNS.HOME_BANNERS];

  const banners = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      data?.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  columns.push({
    label: "Actions",
    key: "actions",
    renderCell: (row) => (
      <ActionButton
        data={row}
        refetch={refetch}
        onEditClick={() =>
          setBannerModal({
            data: row,
            visible: true,
          })
        }
      />
    ),
  });

  return (
    <ContentBlock
      title="Home Banners"
      loading={false}
      rightComponent={
        <Button
          onClick={() =>
            setBannerModal({
              data: undefined,
              visible: true,
            })
          }
          variant="filled"
          color="blue"
        >
          Add Banner
        </Button>
      }
    >
      <DefaultTable
        columns={columns as TTableColumns<unknown>[]}
        data={banners}
        isLoading={isLoading}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
      {bannerModal.visible && (
        <BannerModal
          onClose={() => setBannerModal({ visible: false, data: undefined })}
          show={bannerModal.visible}
          refetch={refetch}
          data={bannerModal.data}
        />
      )}
    </ContentBlock>
  );
};

export default HomeBanners;
