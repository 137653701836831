import { Image } from "@mantine/core";
import React from "react";

export const projectsColumns: TTableColumns<TProjects>[] = [
  {
    label: "Banner",
    key: "banner",
    renderCell: (value) => <Image width={100} src={value.banner} />,
    minWidth: 150,
  },
  {
    label: "Title",
    key: "title",
  },
  {
    label: "Description",
    key: "description",
  },
];
