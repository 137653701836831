import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const get = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.settings.FETCH_SETTINGS,
    method: "GET",
  });
  return response;
};

export const useSettingsQuery = () => {
  return useQuery({
    queryKey: ["admin", "settings"],
    queryFn: get,
  });
};
