import React, { useEffect, useMemo } from "react";
import ContentBlock from "../../components/ContentBlock/ContentBlock";
import { Button, Grid, Image, TextInput } from "@mantine/core";
import { useBlogBannerQuery } from "../../hooks/blog-banner/query/useBlogBanner.query";
import { useUpdateBlogBannerMutation } from "../../hooks/blog-banner/mutation/useUpdateBlogBanner.mutation";
import { useForm, yupResolver } from "@mantine/form";
import { forms } from "../../forms";
import CropPickerInput from "../../components/ImageCropPicker/CropPickerInput";
import { showNotification } from "@mantine/notifications";

const BlogsBanner = () => {
  const { data, isLoading, refetch } = useBlogBannerQuery();

  const form = useForm({
    initialValues: forms.blogBanner.values,
    validate: yupResolver(forms.blogBanner.validation),
  });
  const { setValues } = form;

  useEffect(() => {
    if (!isLoading && data) {
      setValues(data.data);
    } else {
      setValues(forms.blogBanner.values);
    }
    console.log("forms.blogBanner.values", forms.blogBanner.values);
  }, [isLoading, data]);
  console.log("Blog data", data?.data);

  const { isPending, mutateAsync } = useUpdateBlogBannerMutation();

  const handleSubmit = async (values: typeof forms.blogBanner.values) => {
    const formData = new FormData();
    if (typeof values.banner !== "string") {
      formData.append("banner", values.banner, "heroBanner.webp");
    }
    if (typeof values.image !== "string") {
      formData.append("image", values.image, "image.webp");
    }
    formData.append("title", values.title);
    formData.append("_id", values._id);
    const res = await mutateAsync(formData);
    if (res.status === "success") {
      refetch();
      showNotification({ message: res.message, color: "green" });
    } else {
      showNotification({ message: res.data?.message, color: "red" });
    }
  };
  return (
    <ContentBlock title="Blog Banners" loading={false}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div>
          <Grid grow>
            <Grid.Col span={12} sm={6}>
              <Image
                src={
                  typeof form.values?.banner === "string"
                    ? form.values?.banner
                    : URL.createObjectURL(form.values?.banner)
                }
                my={10}
                alt="Banner"
                height={200}
              />
              <CropPickerInput
                name={"banner"}
                label="Hero Banner (ratio: 113 × 40)"
                formHandler={form}
                pickerRatio={113 / 40}
              />
            </Grid.Col>
            <Grid.Col span={12} sm={6}>
              <Image
                src={
                  typeof form.values?.image === "string"
                    ? form.values?.image
                    : URL.createObjectURL(form.values?.image)
                }
                my={10}
                alt="Image"
                height={200}
              />
              <CropPickerInput
                name={"image"}
                label="Title Image (ratio: 312 × 249)"
                formHandler={form}
                pickerRatio={312 / 249}
              />
            </Grid.Col>
          </Grid>

          <TextInput
            label="Title"
            placeholder="Title"
            {...form.getInputProps("title")}
            mt={20}
            mb={20}
          />
          <Button
            type="submit"
            variant="filled"
            color="blue"
            loading={isPending}
            disabled={isPending}
          >
            Save Detail
          </Button>
        </div>
      </form>
    </ContentBlock>
  );
};

export default BlogsBanner;
